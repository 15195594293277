import { Injectable } from '@angular/core';

import { NGXLogger } from 'ngx-logger';
import { Observable, tap, timer } from 'rxjs';
import { NbAuthService } from '@nebular/auth';
@Injectable()
export class ImpersonatingOrganizationCanActivate  {
  IsImpersonatingOrganization: boolean;
  constructor(private authService: NbAuthService, private logger: NGXLogger) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      tap(authenticated => {
        if (!authenticated) {
          //this.router.navigate(['auth/login']);
        }
      })
    );
  }
}
