import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ConfigLoaderService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-oauth2-callback',
  templateUrl: './oauth2-callback.component.html',
  styleUrls: ['./oauth2-callback.component.scss']
})
export class OAuth2CallbackComponent implements OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(
    private localStorage: StorageMap,
    private configLoader: ConfigLoaderService,
    private logger: NGXLogger,
    private authService: NbAuthService,
    private router: Router
  ) {
    this.logger.debug('NbOAuth2CallbackComponent Loaded');

    this.configLoader.Config.subscribe(config => {
      this.authService
        .authenticate(config.authServiceStrategyName)
        .pipe(takeUntil(this.destroy$))
        .subscribe((authResult: NbAuthResult) => {
          this.logger.debug(authResult);
          if (authResult.isSuccess() && authResult.getRedirect()) {
            this.logger.debug('Logged in and redirecting to redirect.');
            this.localStorage.get('LastPageUrlVisited').subscribe((data: any) => {
              this.logger.debug('LastPageUrlVisited: ' + data);
              this.router.navigateByUrl(data);
            });
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
