import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ApplicationService, ResetDatabaseWithDemoDataResult } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppGlobals } from './app.global';
import { ToastService } from './core/common/toast-message/shared/services/toast.service';
import { AnalyticsService } from './core/services/analytics.service';
import { ApplicationOfflineService } from './core/services/application-offline.service';
import { ApplicationUpdateService } from './core/services/application-update-service.service';
import { CachedDataService } from './core/services/cached-data.service';
import { ConfigLoaderService } from './core/services/config-loader.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthModule } from './auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly LastPageUrlVisited = new BehaviorSubject<string>('/');
  public readonly LastPageUrlVisitedCodeName = 'LastPageUrlVisited';
  subscriptions: Subscription[] = [];
  closeResult = '';
  @ViewChild('offlineModule') offlineModule: TemplateRef<any>;
  @ViewChild('AppHelperModalWindow') AppHelperModalWindow: TemplateRef<any>;
  AppHelperModalWindowOptions: NgbModalOptions = {
    ariaLabelledBy: 'modal-basic-title',
    backdrop: 'static',
    centered: true,
    backdropClass: 'blurred',
    keyboard: true
  };
  DemoDataResultText: string = '';
  ShouldShowDemoDataButton: boolean = false;

  constructor(
    protected localStorage: StorageMap,
    private applicationService: ApplicationService,
    public configLoaderService: ConfigLoaderService,
    public toastService: ToastService,
    private logger: NGXLogger,
    private analyticsService: AnalyticsService,
    private applicationUpdateService: ApplicationUpdateService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public cachedDataService: CachedDataService,
    private loadingBar: LoadingBarService,
    private modalService: NgbModal,
    private applicationOfflineService: ApplicationOfflineService
  ) {
    this.applicationUpdateService.checkForUpdates();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe(event => {
        // set the page title
        this.titleService.setTitle(event['breadcrumb']?.toString() + ' | ' + AppGlobals.SITE_NAME);
      });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let shouldStoreUrl = event.url.indexOf(AuthModule.OAuthRootPath) != 0;
        if (shouldStoreUrl) {
          this.LastPageUrlVisited.next(event.url);
          this.localStorage.set(this.LastPageUrlVisitedCodeName, event.url).subscribe(() => {});
        }
      }
    });
  }
  ngAfterViewInit(): void {
    this.logger.debug('this.offlineModule', this.offlineModule);
    this.applicationOfflineService.handleAppConnectivityChanges(this.offlineModule);
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  @HostListener('document:keydown.shift.control.?', ['$event'])
  @HostListener('document:keydown.shift.control./', ['$event'])
  openAppHelperModalWindow(e: KeyboardEvent) {
    this.ShouldShowDemoDataButton =
      ['development', 'devhosted', 'qa', 'demo'].indexOf(this.configLoaderService.Environment.toLowerCase()) > -1;
    this.logger.log('Current environment is ' + this.configLoaderService.Environment);
    this.logger.log('ShouldShowDemoDataButton is ' + this.ShouldShowDemoDataButton);

    if (!this.modalService.hasOpenModals()) {
      this.modalService.open(this.AppHelperModalWindow, this.AppHelperModalWindowOptions);
    }
  }

  public closeAppHelperModalWindow() {
    this.modalService.dismissAll();
  }

  public resetDemoData() {
    this.logger.log('Starting to reset demo data...');
    this.applicationService.resetDatabaseWithDemoData().subscribe((result: ResetDatabaseWithDemoDataResult) => {
      this.logger.log(result);
      this.DemoDataResultText = result.message;
    });
  }
}
