import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService, NbAuthToken, NbTokenService } from '@nebular/auth';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DashboardService } from '@wo-api/index';
import { AppGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services/toast.service';
import { ImpersonationService } from '@wo-app/core/services';
import { CachedDataService } from '@wo-app/core/services/cached-data.service';
import { ConfigLoaderService } from '@wo-app/core/services/config-loader.service';
import { HamburgerService } from '@wo-app/core/side-menu';
import { InventoryImportSignalRService } from '@wo-app/dashboard/services/inventory-import-signal-r.service';
import { IdentityToken } from '@wo-app/shared/models';
import { Nullable } from '@wo-app/shared/models/nullable-type';
import { RouteLink } from '@wo-app/shared/models/route/route-link.model';
import { decodeToken } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  public versionNumber: any;
  public applicationName = AppGlobals.SITE_NAME;
  public logoUrl = AppGlobals.LOGO_URL;
  public CurrentDate = new Date();
  public Config: any;
  public IdToken: IdentityToken | null = null;
  public menuHeight: number = 0;
  public headerMenuIsSticky: boolean = false;
  public children = new Array<RouteLink>();
  public organizationId: Nullable<number>;
  public organizationName: Nullable<string>;
  public isImpersonatingOrganization: boolean;
  public ngxLoadingBarOptions: any = {
    color: '#ff8113',
    includeSpinner: false,
    includeBar: true,
    height: '4px',
    diameter: '14px',
    fixed: false
  };

  @ViewChild('headerStickyMenu') headerStickyMenu: ElementRef;
  @ViewChild('offCanvasMenu') offCanvasMenu: ElementRef;
  @ViewChild('offcanvasButton') offcanvasButton: ElementRef;

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    this.menuHeight = this.headerStickyMenu.nativeElement.offsetHeight;
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.menuHeight) {
      this.headerMenuIsSticky = true;
    } else {
      this.headerMenuIsSticky = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  handleResize(event: Event) {
    let isMobileView = (event.target as Window).innerWidth <= 992;
    let isMobileMenuOpen = this.offcanvasService.hasOpenOffcanvas();

    if (!isMobileView && isMobileMenuOpen) this.closeMobileMenu();
  }

  constructor(
    private toastService: ToastService,
    public offcanvasService: NgbOffcanvas,
    private inventoryImportSignalRService: InventoryImportSignalRService,
    private configLoader: ConfigLoaderService,
    private authService: NbAuthService,
    protected tokenService: NbTokenService,
    public cachedDataService: CachedDataService,
    private logger: NGXLogger,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    private impersonationService: ImpersonationService,
    private dashboardService: DashboardService,
    private hamburgerService: HamburgerService
  ) {
    this.configLoader.Config.subscribe(config => {
      this.logger.debug('Loading Dashboard.component.ts');
      this.Config = config;

      this.authService.getToken().subscribe((authToken: NbAuthToken) => {
        let decoded_token = decodeToken(authToken.getPayload().id_token);
        this.IdToken = new IdentityToken('', '', decoded_token['email'], decoded_token['cognito:username'], decoded_token['phone_number']);
      });
      this.isImpersonatingOrganization = false;
      this.organizationId = null;
      this.organizationName = null;
    });
  }

  public ngOnInit() {}

  public ngAfterViewInit() {
    this.menuHeight = this.headerStickyMenu.nativeElement.offsetHeight;
    this.hamburgerService.setHamburgerElement(this.offcanvasButton);
  }

  public toggleOffCanvasMenu() {
    if (this.offcanvasService.hasOpenOffcanvas()) {
      this.closeMobileMenu();
    } else {
      this.hamburgerService.setActive();
      const offcanvasRef = this.offcanvasService.open(this.offCanvasMenu, { position: 'end', panelClass: 'menu', backdrop: false });
    }
  }

  public onQuickActionClick() {
    this.closeMobileMenu();
  }

  private closeMobileMenu() {
    this.hamburgerService.setInactive();
    this.offcanvasService.dismiss();
  }
}
